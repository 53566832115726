import { useEffect, useState } from "react";
import {
  DrawerForm,
  ModalForm,
  ProFormText,
  ProFormSelect,
  ProFormDatePicker,
  ProFormRadio,
  ProFormTextArea,
  PageContainer,
  ProFormGroup,
  ProFormDigit,
} from "@ant-design/pro-components";
import {
  Row,
  Col,
  Button,
  Space,
  Tag,
  Avatar,
  Typography,
  Image,
  Tooltip,
  Breadcrumb,
  Form,
  Dropdown,
  Badge,
  Card,
  Segmented,
  Table,
  List,
  Divider,
} from "antd";
import moment from "moment-timezone";
import CountUp from "react-countup";
import { Api } from "services";
import { addressFormat } from "utils";

const { Title, Text, Link } = Typography;

export default function Page() {
  const [active, setActive] = useState("Entity");
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const result = await Api.Get(`/vote/list`);
    if (result.data.status === true) {
      setData(result.data.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageContainer
      header={{
        title: "Vote",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/profile",
              title: "My Profile",
            },
            {
              path: "",
              title: "Vote",
            },
          ],
        },
      }}
    >
      <Card
        title={
          <Row align={"middle"} justify={"space-between"}>
            <Col>
              <Title level={5} style={{ margin: 0 }}>
                Vote List
              </Title>
            </Col>
            <Col>
              <Text type="secondary">{`${data.length} Lists`}</Text>
            </Col>
          </Row>
        }
      >
        <List
          itemLayout="vertical"
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              key={item._id}
              extra={<Image width={120} alt="logo" src={`/api/static/data/vote/${item?._id+''+(item?.step=='vote'? '' : '_register')}.png`} fallback={'/icon-512x512.png'}/>}
            >
              <Space size={0} direction={'vertical'}>
                <Link href={`/vote/detail/${item?._id}`}> <Title level={5} style={{marginTop:0,marginBottom:8,padding:0,color:'#24d37d',fontWeight:700}}>{item?.step=='vote'? item?.title : item?.register_title}</Title></Link>
                <Tag color="#24d37d">
                    <i className="far fa-clock"></i>{" "}
                    {`${moment(item?.step=='vote'? item.start_date : item.register_start).format(
                      "DD/MM/YYYY, HH:mm"
                    )} - ${moment(item?.step=='vote'? item.end_date : item.register_end).format(
                      "DD/MM/YYYY, HH:mm"
                    )}`}
                </Tag><br />
                <Text>{item?.step=='vote'? item?.description : item?.register_description}</Text>
              </Space>
              
              
            </List.Item>
          )}
        />
      </Card>
    </PageContainer>
  );
}
