import { useState, useEffect } from "react";
import { PageContainer } from "@ant-design/pro-components";
import { Row, Col, Button, Space, Avatar, Typography, Image, Card } from "antd";
import { Api } from "services";

const { Title, Text } = Typography;

export default function Page() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await Api.Get(`/item/list_active`);
    if (response.data.status === true) {
      setData(response.data.data);
    }
  };

  return (
    <PageContainer
      header={{
        title: "Mission",
        ghost: true,
        breadcrumb: {
          items: [
            {
              path: "",
              title: "My Profile",
            },
            {
              title: "Mission",
            },
          ],
        },
        extra: [
          <Button key="history" icon={<i className="far fa-history"></i>}>
            History
          </Button>,
        ],
      }}
    >
      <Row gutter={[16, 16]}>
        {/* {data.map((item) => (
          <Col key={item._id} md={6}>
            <Card bordered={false}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <div
                    className="kuber-redeem-page-image"
                    style={{
                      backgroundImage: `url(/api/static/items/${item._id}.png)`,
                    }}
                  >
                    <Image
                      src={`/api/static/items/${item._id}.png`}
                      alt={item.name}
                      preview={false}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <Title level={5} style={{ margin: 0 }}>
                    {item.name}
                  </Title>
                </Col>
                <Col span={24}>
                  <Row justify={"space-between"}>
                    <Col>
                      <Space direction="vertical" size={0}>
                        <Text type="secondary">
                          <small>Remaining</small>
                        </Text>
                        <Text strong>
                          <small>
                            {item.total_amount - item.current_amount}
                          </small>
                        </Text>
                      </Space>
                    </Col>
                    <Col align="right">
                      <Space direction="vertical" size={0}>
                        <Text type="secondary">
                          <small>Value</small>
                        </Text>
                        <Space size={2}>
                          <Avatar src="/token/KBG.png" size={18} />
                          <Text strong>
                            <small>{item.kbg}</small>
                          </Text>
                        </Space>
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Button
                    block
                    disabled={item.total_amount - item.current_amount === 0}
                  >
                    Redeem
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        ))} */}
      </Row>
    </PageContainer>
  );
}
