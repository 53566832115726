//import "./index.css";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";
import {
  Row,
  Col,
  Button,
  Space,
  Avatar,
  Typography,
  Image,
  Card,
  Descriptions,
  Timeline,
  Progress,
  Alert,
  Radio,
  Spin,
  Modal
} from "antd";
import { Api } from "services";
import { Pie } from "@ant-design/plots";
import moment from "moment-timezone";

const { Title, Text, Paragraph } = Typography;

export default function Page() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal()
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [total, setTotal] = useState(0);
  const [totalRegister, setTotalRegister] = useState(0);
  const [on, setOn] = useState(true);
  const [found, setFound] = useState(0);
  const [score, setScore] = useState([]);
  const [step, setStep] = useState('vote');


  const [value, setValue] = useState(false);

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = async (id) => {
    const result = await Api.Get(`/champion/view/${id}`);
    console.log('result fetchData===>',`/champion/view/${id}`,result.data)
    if (result.data.status === true) {
      setData(result.data.data);
      setLoading(false);
    } else {
      navigate(`/champion`);
    }
  };

 

  const confirm = async() => {
    var res = await modal.confirm({
      title: 'Confirm',
      content: 'Confirm to vote?',
      okText: 'Yes',
      cancelText: 'No',
    });
    if(res){
      setLoading(true);
      const response = await Api.Post(`/vote/submit/${id}`, {choice:parseFloat(value),comment:''});
      console.log('response===>',response,response.data.status)
      if (response.data.status === true) {
        setLoading(false);
        modal.success({
          centered: true,
          title: "Successfully",
          content: <Text>{response.data.message}</Text>
        });
        fetchData(id)
      } else {
        setLoading(false);
        modal.error({
          centered: true,
          title: "Unsuccessfully",
          content: <Text>{response.data.message}</Text>
        });
      }
    }
  };

  const confirmRegister = async() => {
    var res = await modal.confirm({
      title: 'Confirm',
      content: 'Confirm to Register?',
      okText: 'Yes',
      cancelText: 'No',
    });
    if(res){
      setLoading(true);
      const response = await Api.Post(`/vote/submit_register/${id}`, {});
      console.log('response===>',response,response.data.status)
      if (response.data.status === true) {
        setLoading(false);
        await fetchData(id);
        modal.success({
          centered: true,
          title: "Successfully",
          content: <Text>{response.data.message}</Text>
        });
      } else {
        setLoading(false);
        modal.error({
          centered: true,
          title: "Unsuccessfully",
          content: <Text>{response.data.message}</Text>
        });
      }
    }
  }



  return (
    <PageContainer
      header={{
        title: step=='register'? data?.register_title : data?.title,
        ghost: true,
        breadcrumb: {
          items: [
            {
              path: "",
              title: "My Profile",
            },
            {
              href: "/vote",
              title: "Vote",
            },
            {
              title: step=='register'? data?.register_title : data?.title,
            },
          ],
        },
      }}
    >
    <Spin spinning={loading}>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={12} md={12}>
          <Space direction="vertical" style={{width:'100%'}}>
          <Image alt="logo" src={`/api/static/data/vote/${data?._id+''+(step=='register'? '_register' : '') }.png`}  fallback={'/icon-512x512.png'} width={'100%'} />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={12}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Card bordered={false}>
                <Title level={5} style={{ marginTop: 0 }}>
                  {step=='register'? data?.register_title : data?.title}
                </Title>
                <Paragraph type="secondary">
                  <div dangerouslySetInnerHTML={{__html: step=='register'? data?.register_description :data?.description}}></div>
                </Paragraph>
              </Card>
            </Col>

           {step=='register'? (
             <>

              <Col span={24}>
                <Card bordered={false}>

                  <Space
                    direction="vertical"
                    size={"large"}
                    style={{ display: "flex" }}
                  >
                    <Title level={5} style={{ marginTop: 0 }}>สมัครลงเลือกตั้ง</Title>
                    <Text>ทำการกดปุ่ม register เพื่อเข้าร่วมลงสมัครตัวแทน</Text>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ display: "flex" }}
                    >

                      <Text type="secondary">
                        <small>
                          <i class="fas fa-user"></i>{" "}
                          {'Total '+totalRegister+' register'}
                        </small>
                      </Text>

                      <Text type="secondary">
                        <small>
                          <i class="fas fa-clock"></i>{" "}
                          {`${moment(step=='register'? data?.register_start : data?.start_date).format("DD/MM/YYYY, HH:mm")}
                           - 
                          ${moment(step=='register'? data?.register_end : data?.end_date).format("DD/MM/YYYY, HH:mm")}`}
                        </small>
                      </Text>
                    </Space>
                    {found==false? (
                    <Button type="primary" block shape="round" onClick={confirmRegister}>
                      Register
                    </Button>) : (
                      <Alert message="ท่านได้ทำการสมัครเข้าร่วมเรียบร้อยแล้ว" type="success" />
                    )}
                
                  </Space>
                </Card>
              </Col>
             </>
           ) : (
             <>
               {found==false? (
                <Col span={24}>
                  <Card bordered={false}>
                    {on==1? (
                    <>
                      <Title level={5} style={{ marginTop: 0 }}>
                        Choice
                      </Title>
                      <Radio.Group onChange={onChange} value={value} style={{width:'100%'}}>
                        <Space direction="vertical" style={{width:'100%'}}>
                          {data?.choice.map((item,index)=>{
                            var this_data = item.split('|')
                            return (
                              <>
                              {this_data.length>1? (
                                <>
                                <Radio value={index.toString()}>
                                  <Space size={4}>
                                    <Avatar src={this_data[1]} size={20} />
                                    <Text>{this_data[2]}</Text>
                                  </Space>
                                </Radio>
                                <Link to={"/profile/"+this_data[0]} target="_blank"><Text style={{marginLeft:30}}><small>View profile</small></Text></Link>
                                </>
                              ) : (
                                <Radio value={index.toString()}>
                                  <Text>{this_data[0]}</Text>
                                </Radio>
                              )}
                              </>
                            )
                          })}
                        </Space>
                      </Radio.Group>
                    </>
                    ) : on==2? (      
                    <>        
                        <Title level={5} style={{ marginTop: 0 }}>
                          Choice
                        </Title>  
                        <Space direction="vertical" style={{width:'100%'}}>
                          {data?.choice.map((item,index)=>{
                            var this_data = item.split('|')
                            return (
                              <>
                              {this_data.length>1? (
                              <Space size={4}>
                                <Avatar src={this_data[1]} size={20} />
                                <Text>
                                  <small>{this_data[2]}</small>
                                </Text>
                              </Space>
                              ) : (
                                <Text>{this_data[0]}</Text>
                              )}
                              <Progress
                                percent={(((score.filter(node=>node._id==index)[0]?.count || 0) / total) *100).toFixed(0) }
                                status="active"
                                strokeColor={{ from: "#24d37d", to: "#24d37d" }}
                              />
                              </>
                            )
                          })}
                        </Space>
                      </>) : (
                          <>
                            <Alert message="Coming soon" type="success" />
                          </>
                      )}
                  </Card>
                </Col>
               ) : (
                <Col span={24}>
                  <Card bordered={false}><Alert message="ท่านได้ทำการ vote เรียบร้อยแล้ว" type="success" /></Card>
                </Col>
               )}


              <Col span={24}>
                <Card bordered={false}>
                  <Space
                    direction="vertical"
                    size={"large"}
                    style={{ display: "flex" }}
                  >
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ display: "flex" }}
                    >
                      <Text type="secondary">
                        <small>
                          <i class="fas fa-user"></i>{" "}
                          {'Total '+total+' vote'}
                        </small>
                      </Text>
                      <Text type="secondary">
                        <small>
                          <i class="fas fa-clock"></i>{" "}
                          {`${moment(data?.start_date).format(
                            "DD/MM/YYYY, HH:mm"
                          )} - ${moment(data?.end_date).format(
                            "DD/MM/YYYY, HH:mm"
                          )}`}
                        </small>
                      </Text>
                    </Space>
                    {(on==1 && found==false) && (
                    <Button type="primary" block shape="round" disabled={value && value!=''? false : true } onClick={confirm}>
                      Vote
                    </Button>
                    )}
                  </Space>
                </Card>
              </Col>


             </>
           )}




            
          </Row>
        </Col>
      </Row>
      {contextHolder}
      </Spin>
    </PageContainer>
  );
}
