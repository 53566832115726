import { useEffect, useState } from "react";
import {
  DrawerForm,
  ModalForm,
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormDatePicker,
  ProFormRadio,
  ProFormTextArea,
  PageContainer,
  ProFormGroup,
  ProFormDigit,
  ProFormUploadDragger,
  ProFormUploadButton,
  CheckCard
} from "@ant-design/pro-components";
import { Dragger, InboxOutlined, CloseOutlined, LeftOutlined, TrophyTwoTone, EditOutlined, SettingOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
  Result, 
  Spin,
  Flex,
  Tabs,
  Row,
  Col,
  Radio,
  Button,
  Space,
  Tag,
  Avatar,
  Typography,
  Image,
  Tooltip,
  Breadcrumb,
  Form,
  Dropdown,
  Badge,
  Card,
  Segmented,
  Table,
  List,
  Divider,
  AutoComplete,
  Modal,
  Upload as AntdUpload
} from "antd";
import moment from "moment-timezone";
import CountUp from "react-countup";
import debounce from "lodash/debounce";
import { Api } from "services";
import { addressFormat, generateUsername } from "utils";
import { Upload } from "components";
import "./index.css";
const { Title, Text, Link } = Typography;



const columns = [
  {
    title: 'Nominate',
    dataIndex: 'nominate',
    key: 'nominate',
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (_, record) => (<Text>{moment(record.created_at).format("DD/MM/YYYY, HH:mm:ss")}</Text>)
  },
];

 const listVote = [{
    id:1,
    title:"1. Earn Trust Champion",
    detail:"รางวัลนี้มอบให้กับผู้ที่ได้รับความไว้วางใจจากเพื่อนร่วมงาน ผ่านการทำงานอย่างซื่อสัตย์สุจริต โปร่งใส ถูกต้อง รักษาความน่าเชื่อถือ และความปลอดภัยของข้อมูลสำคัญของทีมและองค์กรอยู่เสมอ"
  },
  {
    id:2,
    title:"2. Passionate & Dive Deep Champion",
    detail:"รางวัลสำหรับผู้ที่มีความเข้าใจในธุรกิจและงานที่ทำอย่างลึกซึ้ง มีความเชี่ยวชาญที่สามารถสร้างชิ้นงานที่มีคุณค่าต่อทีมและองค์กรได้"
  },
  {
    id:3,
    title:"3. Challenge & Commit Champion",
    detail:"รางวัลสำหรับผู้ที่กล้าแสดงความคิดเห็นอย่างตรงไปตรงมา พร้อมรับฟังความคิดเห็น อย่างเข้าใจและให้เกียรติและนำข้อมูลที่มีมาต่อยอดการทำงาน และลุยไปด้วยกันจนสุดทาง"
  },
  {
    id:4,
    title:"4. Strive for Results & Actions Champion",
    detail:"รางวัลสำหรับผู้ที่วางแผนอย่างรอบคอบ และลงมือทำอย่างชาญฉลาด โดยไม่ลังเลหรือปล่อยให้อุปสรรคมาขวางเป้าหมายที่วางไว้ พร้อมใช้ไหวพริบในการตัดสินใจ เพื่อให้ผลลัพธ์ออกมาดีที่สุด"
  },
  {
    id:5,
    title:"5. Ownership & Beyond Champion",
    detail:"รางวัลนี้มอบให้แก่ผู้ที่มีความรับผิดชอบทั้งในงานของตนเองและทีม พร้อมมุ่งมั่นช่วยเหลือผู้อื่นแม้เกินกว่าขอบเขตงานที่ได้รับมอบหมาย ด้วยการแสดงศักยภาพที่เหนือความคาดหมาย เพื่อยกระดับเป้าหมายของทีมและองค์กรให้ก้าวไปอีกขั้น"
  },
  {
    id:6,
    title:"6. Adapt & Innovate Champion",
    detail:"รางวัลสำหรับผู้ที่นำมุมมองและความรู้ใหม่ ๆ มาปรับใช้กับการทำงาน ปรับตัวกับความเปลี่ยนแปลง เรียนรู้จากความผิดพลาดที่เกิดขึ้น และใช้เป็นโอกาสในการพัฒนาตนเองและองค์กร"
  },
  {
    id:7,
    title:"7. Empathy & Collaboration Champion",
    detail:"รางวัลนี้มอบให้กับผู้ที่ใส่ใจและเข้าใจถึงความท้าทายและปัญหาในการทำงานของผู้อื่น โดยปราศจากอคติ อีกทั้งยังคอยยินดีกับความตั้งใจของเพื่อนร่วมงานอยู่เสมอ รวมถึงสามารถร่วมงานกับผู้อื่นได้อย่างราบรื่น"
  },
  {
    id:8,
    title:"8. Advocate Champion",
    detail:"รางวัลนี้มอบให้แก่ผู้ที่เป็นตัวอย่างในการผลักดัน และขับเคลื่อนสิ่งต่าง ๆ ภายในองค์กรเพื่อสร้างการเปลี่ยนแปลงในทางที่ดีขึ้น เต็มไปด้วยความกระตือรือร้น และลงมือทำสิ่งนั้นจนเป็นที่ประจักษ์อยู่เสมอ"
  },
  {
    id:9,
    title:"9. Get **it Done Champion",
    detail:"รางวัลนี้ส่งเสริมผู้ที่ลงมือทำสิ่งต่าง ๆ รับจบทุกงาน ไม่ว่างาน challenge แค่ไหนก็ปิดจบได้ พุ่งสุดตัวเพื่อให้งานสำเร็จ และยึดเป้าหมายของทีมและองค์กรป็นที่ตั้ง"
  },
  {
    id:10,
    title:"10. Go Green Champion",
    detail:"รางวัลนี้มอบให้แก่พนักงานที่ส่งเสริมการใช้ทรัพยากรอย่างยั่งยืน ผ่านการรีไซเคิล การนำกลับมาใช้ใหม่ และมีแนวคิด \"Save The World\" โดยมุ่งมั่นสร้างแรงบันดาลใจให้ทุกคนร่วมสร้างอนาคตที่เป็นมิตรกับสิ่งแวดล้อม และคำนึงถึงความยั่งยืน"
  },
  {
    id:11,
    title:"11. Behind the (big) scene Champion",
    detail:"รางวัลนี้ขอชื่นชมให้กับผู้ที่อยู่เบื้องหลังความสำเร็จของโปรเจกต์ ทำงานอย่างทุ่มเท เสียสละทั้งกายและใจ เป็นเบื้องหลังที่สำคัญในการขับเคลื่อนให้งานออกมาสำเร็จได้ หากไม่มีเค้างานจะไม่มีทางออกมาสมบูรณ์ได้เลย!"
  },
  {
    id:12,
    title:"12. Believe & Beyond Champion",
    detail:"รางวัลนี้แสดงถึงผู้ที่มีความเชื่อมั่นในการขับเคลื่อนวัฒนธรรมองค์กรผ่านค่านิยมหลักได้อย่างครบถ้วน นำหนึ่งก้าวเสมอ เป็นแรงบันดาลใจให้กับเพื่อนร่วมงาน และส่งเสริมให้คนในองค์กรบรรลุเป้าหมายเดียวกัน"
  }]
 









export default function Page() {
  const [form] = Form.useForm();

  const [openModel, setOpenModel] = useState(false);
  const [step, setStep] = useState(0);
  const [allow, setAllow] = useState(false);
  const [tmpAward, setTmpAward] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [selectAward, setSelectAward] = useState(false);
  const [loading,setLoading] = useState(false);
  const [presentType, setPresentType] = useState(false);
  const [active, setActive] = useState("Entity");
  const [dataSource, setDataSource] = useState([]);
  const [dataForm, setDataForm] = useState(false);
  const [modal, contextHolder] = Modal.useModal()

  const fetchData = async (id) => {
    const result = await Api.Get(`/champion/view/${id}`);
    if (result.data.status === true) {
      setDataSource(result.data.data);
    }
  };

  const onOpenModal = (data) => {
    fetchData(data.id)
    setTmpAward(data)
    setOpenModel(true);
  };
  const onOkModal = () => {
    setSelectAward(tmpAward)
    setOpenModel(false);
  };
  const onCancelModal = () => {
    setTmpAward(false);
    setSelectAward(false);
    setOpenModel(false);
    setPresentType(false)    
    form.setFieldsValue({
      "nominate": '',
      "image": [],
      "drive": '',
      "message_event": '',
      "message_result": '',
      "message_why": '',
      "option_doc": '',
    });
    setStep(0)
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  async function handleSearch(keyword) {
    if (keyword == "") {
      setEmployee([]);
    } else {
      const response = await Api.Get(`/employee/search/${keyword}`);
      if (response.data.status === true) {
        if (response.data.data.length > 0) {
          let data = [];
          for (var i = 0; i < response.data.data.length; i++) {
            data.push({
              value: generateUsername(response.data.data[i].company_email),
              label: (
                <Space>
                  <Avatar src={response.data.data[i].avatar} size="small" />
                  <Text>
                    {generateUsername(response.data.data[i].company_email)}
                  </Text>
                </Space>
              ),
            });
          }
          setEmployee(data);
        } else {
          form.setFieldsValue({});
          setEmployee([]);
        }
      }
    }
  }

  const onFinish = async(value)=>{
    setStep(1)
    setDataForm(value)
  }

  const onEnd= async()=>{
    var res = await modal.confirm({
      title: 'Confirm',
      content: 'คลิกเพื่อยืนยันการเสนอชื่อประเภทรางวัลนี้',
      okText: 'Yes',
      cancelText: 'No',
    });
    if(res){
      
      setLoading(true);
      const response = await Api.Post(`/champion/save/${selectAward.id}`, dataForm);
      console.log('response===>',response,response.data.status)
      if (response.data.status === true) {
        setLoading(false);
        form.setFieldsValue({
          "nominate": '',
          "image": [],
          "drive": '',
          "message_event": '',
          "message_result": '',
          "message_why": '',
          "option_doc": '',
        });
        // modal.success({
        //   centered: true,
        //   title: "Successfully",
        //   content: <Text>{response.data.message}</Text>
        // });
        setStep(2)
      } else {
        setLoading(false);
        modal.error({
          centered: true,
          title: "Unsuccessfully",
          content: <Text>{response.data.message}</Text>
        });
      }
    }

  }

  const getFile = (image) => {
    form.setFieldsValue({
      image,
    });
  };

  return (
    <PageContainer
      header={{
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/profile",
              title: "My Profile",
            },
            {
              path: "",
              title: "Champion",
            },
          ],
        },
      }}
    >

      {!selectAward && (
      <>
        <Card cover={<img alt="example" src="/bg_champion_n.png"/>} bordered={false} style={{marginBottom:16,textAlign:'center'}}>
            <Title level={3} style={{color:'#24d37d',textAlign:'center',fontSize:28}}>ครั้งแรกของ OneBitkub Champion</Title>
            <Title level={5} style={{textAlign:'center',fontSize:18}} >ที่มอบให้กับ Bitkuber ที่แสดงออกถึงค่านิยมหลักขององค์กรได้ดี เป็นตัวอย่างในการร่วมสร้างวัฒนธรรมองค์กรของ Bitkub ผ่านการกระทำที่เห็นผลได้อย่างชัดเจน </Title>
        </Card>
        <Text>** กรุณาทำการกดเลือกหัวข้อที่ต้องการ Nominate ได้เลย<br /><br /></Text>
        <Row gutter={16}>
          {listVote.map(item=>{
            return (
              <Col xs={24} md={12} lg={8}  style={{marginBottom:16}}>
                <Card bordered={false} hoverable style={{cursor:'pointer',height:'100%'}} onClick={()=>onOpenModal(item)}>
                  <TrophyTwoTone twoToneColor="#24d37d" style={{fontSize:60,float:'left',marginRight:10}}/>
                  <Title level={5} style={{marginTop:0,marginBottom:8,padding:0,color:'#24d37d',fontWeight:700}}>{item.title}</Title>
                  <Text>{item.detail}</Text>
                 
                </Card>
              </Col>
            )
          })}
        </Row>
      </>)}
      {selectAward && (
      <Spin spinning={loading}>
        <Row gutter={16}>
                <Col xs={24} md={11} style={{marginBottom:16}}>
                  <Card cover={<img alt="example" src="/bg_champion.png"/>} bordered={false} style={{marginBottom:16}}>
                    <div>
                      <Button type="primary" icon={<CloseOutlined style={{fontSize:20,color:'#ffffff'}}/>} size={'large'} style={{float:'right',marginLeft:20,color:'#24d37d'}} onClick={()=>onCancelModal()}/>
                      <Title level={3} style={{color:'#24d37d'}}>{selectAward?.title}</Title>
                      <Text>{selectAward?.detail}</Text>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} md={1} style={{marginBottom:16}}></Col>
                <Col xs={24} md={12} style={{marginBottom:16}}>
                <Tabs
                  defaultActiveKey="1"
                  items={[
                    {
                      label: 'Form Nominate',
                      key: '1',
                      children: (
                        <div>
                        {step==0 && (<ProForm 
                          onFinish={(values) => onFinish(values)} 
                          form={form} 
                          submitter={presentType==false ? {
                              render: (props, doms) => {
                                return []
                              }
                          } : {
                            render: (props, doms) => {
                              console.log(props);
                              return [
                                <Button
                                  type="button"
                                  key="rest"
                                  onClick={() => props.form?.resetFields()}
                                >
                                  Reset
                                </Button>,
                                <Button
                                  type="primary"
                                  key="submit"
                                  onClick={() => props.form?.submit?.()}
                                >
                                  Confirm
                                </Button>,
                              ];
                            },
                          }}

                        >
                          <Title level={5} style={{color:'#24d37d'}}>ค้นหารายชื่อ ตัวเอง หรือผู้ที่ต้องการส่งเข้า nominate </Title>
                          <ProForm.Item name="nominate" label="ประสงค์เสนอชื่อ" rules={[{ required: true, message: "" }]}>
                            <AutoComplete
                              onSearch={debounce(handleSearch, 500)}
                              placeholder="Search user by email (eg. nick.name)"
                              options={employee}
                            />
                          </ProForm.Item>
                          <Divider />
                          <Title level={5} style={{color:'#24d37d'}}>กรุณาตอบคำถามดังต่อไปนี้ ในรูปแบบการบรรยายหรือวิดีโอ </Title>
                          <Flex gap="small" wrap style={{padding:'20px 0'}}>
                            <Button type={presentType!="doc"? "default" : "primary"} variant="filled" onClick={()=>setPresentType('doc')}>
                              พิมพ์บรรยาย
                            </Button>
                            <Button type={presentType!="video"? "default" : "primary"} variant="filled" onClick={()=>setPresentType('video')}>
                              บรรยายผ่านวิดีโอ
                            </Button>
                          </Flex>
                          {presentType=="doc" && (<>
                            <ProFormTextArea
                              name="message_event"
                              label={(<Text><strong style={{color:'#24d37d'}}>1. เล่าตัวอย่างเหตุการณ์ที่เกิดขึ้น</strong> - อธิบายสถานการณ์ที่เกิดขึ้น และวิธีการจัดการ  (0/1000 character)</Text>)}
                              fieldProps={{
                                rows:4,
                                placeholder:"",
                                maxLength:1000,
                                showCount:true
                              }}
                            />
                            <ProFormTextArea
                              name="message_result"
                              label={(<Text><strong style={{color:'#24d37d'}}>2. ผลลัพธ์</strong> - อธิบายผลลัพธ์ที่เกิดจากการทำงานของผู้ประกวด (0/1000 character)</Text>)}
                              fieldProps={{
                                rows:4,
                                placeholder:"",
                                maxLength:1000,
                                showCount:true
                              }}
                            />
                            <ProFormTextArea
                              name="message_why"
                              label={<Text><strong style={{color:'#24d37d'}}>3. เพราะเหตุใด</strong> คุณหรือเพื่อนร่วมงาน จึงเหมาะสมที่จะได้รับรางวัลนี้ (0/1000 character)</Text>}
                              fieldProps={{
                                rows:4,
                                placeholder:"",
                                maxLength:1000,
                                showCount:true
                              }}
                            />
                          </>)}
                          {presentType=="video" && (
                            <ProFormText name="drive" placeholder="https://..." label="รูปแบบลิงค์วิดีโอ (โดยจะต้องเปิดสิทธิเป็น public หรือ viewer ด้วยอีเมล @bitkub.com)"/>
                          )}
                          {presentType!=false && (<>
                          <Divider/>
                          <Title level={5} style={{color:'#24d37d'}}>แนบสื่อประกอบการพิจารณา (เพิ่มเติม) </Title>
                          <ProFormText name="option_doc" placeholder="https://..." label={(<span>โดยจะต้องเปิดสิทธิเป็น public หรือ viewer ด้วยอีเมล @bitkub.com <br />(ข้อมูลที่ท่านส่งเข้ามาจะใช้ในการพิจารณารางวัลเท่านั้น และจะลบออกหลังจากกิจกรรมนี้เสร็จสิ้นภายใน 30 วัน)</span>)}/>
                          <Divider/>
                
                          <Text>*คณะกรรมการจะตัดสินจากการตอบคำถามเป็นหลัก เพื่อประโยชน์สูงสุดและความโปร่งใสของการพิจารณา</Text>
                          <br/><br/>
                          </>)}

                        </ProForm>)}
                        {step==1 && (<div>
                         <ProForm 
                            submitter={{
                              resetButtonProps: {
                                style: {
                                  display: 'none',
                                },
                              },
                              render: (props, doms) => {
                                return [
                                  <Button
                                    type="button"
                                    key="rest"
                                    onClick={() => setStep(0)}
                                  >
                                    Back
                                  </Button>,
                                  <Button
                                    type="primary"
                                    key="submit"
                                    onClick={() => props.form?.submit?.()}
                                  >
                                    Submit
                                  </Button>,
                                ];
                              },
                            }}
                           readonly 
                           onFinish={(values) => onEnd(values)} 
                           initialValues={dataForm}
                         >
                           <Title level={5} style={{color:'#24d37d'}}>ยืนยันข้อมูล</Title>
                           <ProFormText name="nominate" style={{color:'#ffffff'}}/>
                           <Divider />
                           
                          {presentType=="doc" && (<>
                            <ProFormTextArea
                              name="message_event"
                              label={(<Text><strong style={{color:'#24d37d'}}>1. เล่าตัวอย่างเหตุการณ์ที่เกิดขึ้น</strong> - อธิบายสถานการณ์ที่เกิดขึ้น และวิธีการจัดการ  (0/1000 character)</Text>)}
                              fieldProps={{
                                style:{color:'#ffffff'},
                                rows:4,
                                placeholder:"",
                                maxLength:1000,
                                showCount:true
                              }}
                            />
                            <ProFormTextArea
                              name="message_result"
                              label={(<Text><strong style={{color:'#24d37d'}}>2. ผลลัพธ์</strong> - อธิบายผลลัพธ์ที่เกิดจากการทำงานของผู้ประกวด (0/1000 character)</Text>)}
                              fieldProps={{
                                style:{color:'#ffffff'},
                                rows:4,
                                placeholder:"",
                                maxLength:1000,
                                showCount:true
                              }}
                            />
                            <ProFormTextArea
                              name="message_why"
                              label={<Text><strong style={{color:'#24d37d'}}>3. เพราะเหตุใด</strong> คุณหรือเพื่อนร่วมงาน จึงเหมาะสมที่จะได้รับรางวัลนี้ (0/1000 character)</Text>}
                              fieldProps={{
                                style:{color:'#ffffff'},
                                rows:4,
                                placeholder:"",
                                maxLength:1000,
                                showCount:true
                              }}
                            />
                          </>)}
                          {presentType=="video" && (
                            <ProFormText name="drive" placeholder="https://..." label="รูปแบบลิงค์วิดีโอ (โดยจะต้องเปิดสิทธิเป็น public หรือ viewer ด้วยอีเมล @bitkub.com)"/>
                          )}
                          <Divider/>
                          <Title level={5} style={{color:'#24d37d'}}>สื่อที่ท่านแนบประกอบการพิจารณา (เพิ่มเติม) </Title>
                          <ProFormText name="option_doc" placeholder="https://..." label={(<span>โดยจะต้องเปิดสิทธิเป็น public หรือ viewer ด้วยอีเมล @bitkub.com <br />(ข้อมูลที่ท่านส่งเข้ามาจะใช้ในการพิจารณารางวัลเท่านั้น และจะลบออกหลังจากกิจกรรมนี้เสร็จสิ้นภายใน 30 วัน)</span>)}/>
                          <Divider/>
                    
                          
                          <Text>หากท่านกดยืนยันแล้วจะไม่สามารถแก้ไขข้อมูลได้</Text>
                          <br/><br/>
                          </ProForm>
                        </div>)}
                        {step==2 && (<div>
                         <Result
                            status="success"
                            title={(<Text style={{fontSize:20,color:'#ffffff'}}>ขอขอบคุณที่มาเป็นส่วนหนึ่งในการสร้าง Recognition Culture</Text>)}
                            subTitle={(
                            <div style={{marginTop:30,marginBottom:30}}>
                              <Text style={{fontSize:15}}>
                                โดยได้ร่วมชื่นชม และเสนอชื่อผู้ที่เหมาะสมใน OneBitkub Champion <br/>แล้วมาร่วมลุ้นผู้ที่ได้รับรางวัลไปพร้อมกันในงาน OneBitkub All Hands 2025 นะคะ<br/><br/>
                                โดยท่านจะได้รับ 1 สิทธิในการร่วมลุ้นกิจกรรม Lucky Draw
                              </Text>
                            </div>)}
                            extra={[
                              <Button onClick={()=>{
                                onCancelModal()
                              }}>Back</Button>,
                            ]}
                          />
                        </div>)}
                        </div>),
                      },{
                      label: 'Nominate History',
                      key: '2',
                      children: (<>
                          <Title level={5} style={{color:'#24d37d'}}>ประวัติรายการส่งเข้า nominate</Title>
                          <Table dataSource={dataSource} columns={columns} />
                        </>),
                      }
                    ]}
                  />
                </Col>
              </Row>
      </Spin>
      )}


      <Modal
        title="เงื่อนไข"
        open={openModel}
        onOk={onOkModal}
        width={900}
        onCancel={onCancelModal}
        okText="Agree"
        cancelText="Reject"
      >
        <Text>
          <ul>
            <li>พนักงานทุกคนสามารถเสนอชื่อตนเอง หรือเพื่อนร่วมงาน เข้าประกวดในสาขาต่าง ๆ</li>
            <li>ผู้ถูกเสนอชื่อเข้าประกวด จะต้องเป็นตัวอย่างที่ดี และสร้างแรงบันดาลในการทำงานผ่าน Core Values และวัฒนธรรมองค์กร</li>
            <li>ในการเสนอชื่อ ผู้เสนอจะต้องส่งเหตุผลผ่านการตอบคำถามในรูปแบบการบรรยายหรือแนบลิงค์วิดีโอ โดยจะต้องเปิดสิทธิเป็น public หรือ viewer ด้วยอีเมล @bitkub.com โดยวิดีโอความยาวไม่เกิน 5 นาที  (โปรดเลือกรูปแบบการตอบคำถามอย่างใดอย่างหนึ่ง)</li>
            <li>หากมีสื่อประกอบให้พิจารณา (เพิ่มเติม) สามารถแนบลิงค์ส่งเข้ามาได้ โดยจะต้องเปิดสิทธิเป็น public หรือ viewer ด้วยอีเมล @bitkub.com</li>
          </ul>
        </Text>

      </Modal>
      {contextHolder}



    </PageContainer>
  );
}
