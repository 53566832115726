import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ConfigProvider,
  Layout,
  Menu,
  Row,
  Col,
  Space,
  Typography,
  Avatar,
  Image,
  Button,
  Divider,
  Card,
  Switch,
  Tooltip,
  Badge,
  AutoComplete,
  Dropdown,
  Input,
  Modal,
} from "antd";
import { DrawerForm, ProList } from "@ant-design/pro-components";
import enUSIntl from "antd/lib/locale/en_US";
import debounce from "lodash/debounce";
import token from "styles/global";
import lightAlgorithm from "styles/light";
import darkAlgorithm from "styles/dark";
import { Api } from "services";
import moment from "moment-timezone";
import CountUp from "react-countup";
import { Wallet, Waiting } from "components";
import {
  generateMenu,
  addressFormat,
  generateUsername,
  getCoreValue,
} from "utils";

const numberFormatter = Intl.NumberFormat("en-US");
const { Header, Footer, Sider, Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const { success, error } = Modal;

function isCalc(currentMonth, lastMonth) {
  return {
    increase: numberFormatter.format(currentMonth.totalAmount),
    percent: numberFormatter.format(
      ((currentMonth.totalAmount - lastMonth.totalAmount) * 100) /
        lastMonth.totalAmount
    ),
  };
}

const LayoutMain = ({ data }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [supplyKBS, setSupplyKBS] = useState(0);
  const [supplyKBG, setSupplyKBG] = useState(0);
  const [analyKBS, setAnalyKBS] = useState(null);
  const [analyKBG, setAnalyKBG] = useState(null);
  const [latest, setLatest] = useState([]);
  const [options, setOptions] = useState([]);
  const [keyword, setKeyword] = useState(null);
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);

  const menuDropdown = [
    {
      label: "My Profile",
      icon: <i className="far fa-user"></i>,
      key: "my_profile",
    },

    {
      type: "divider",
    },
    {
      label: "Sign out",
      icon: <i className="far fa-sign-out"></i>,
      key: "sign_out",
      danger: true,
    },
  ];

  useEffect(() => {
    // if (localStorage.getItem("isDarkMode")) {
    //   setIsDarkMode(
    //     localStorage.getItem("isDarkMode") === "false" ? !!"" : !!"true"
    //   );
    //   document.body.className =
    //     localStorage.getItem("isDarkMode") === "true"
    //       ? "dark-theme"
    //       : "light-theme";
    // } else {
    //   localStorage.setItem("isDarkMode", "true");
    //   document.body.className = "dark-theme";
    // }
    document.body.className = "dark-theme";
  }, []);

  async function handleSearch(keyword) {
    const response = await Api.Get(`/employee/mention/${keyword}`);
    if (response.data.status === true) {
      let data = [];
      for (var i = 0; i < response.data.data.length; i++) {
        data.push({
          value: response.data.data[i]._id,
          label: (
            <Space>
              <Avatar src={response.data.data[i].avatar} size="small" />
              <Text>{response.data.data[i].company_email.split("@")[0]}</Text>
            </Space>
          ),
        });
      }
      setOptions(data);
    }
  }

  function handleSelect(value) {
    setKeyword(null);
    window.open(`/profile/${value}`, "_blank", "noopener,noreferrer");
  }

  function handleChange(value) {
    setKeyword(value);
  }

  function handleBlur() {
    setKeyword(null);
  }

  async function handleSignOut() {
    const response = await Api.Get(`/auth/sign_out`);
    if (response.data.status === true) {
      navigate(`/login`);
    }
  }

  async function handleMenuClick(value) {
    if (value.key === "my_profile") {
      navigate(`/profile/${data._id}`);
    }
    if (value.key === "sign_out") {
      handleSignOut();
    }
  }

  useEffect(() => {
    async function getWatchList() {
      const response = await Api.Get(`/token/watchlist`);
      if (response.data.status === true) {
        setSupplyKBS(response.data.balanceKBS);
        setSupplyKBG(response.data.balanceKBG);
        setAnalyKBS(
          isCalc(
            response.data.kbs[response.data.kbs.length - 1],
            response.data.kbs[response.data.kbs.length - 2]
          )
        );
        setAnalyKBG(
          isCalc(
            response.data.kbg[response.data.kbg.length - 1],
            response.data.kbg[response.data.kbg.length - 2]
          )
        );
      }
    }
    async function getLatest() {
      const response = await Api.Get(`/latest/send_kbs`);
      if (response.data.status === true) {
        setLatest(response.data.data);
      }
    }
    getWatchList();
    getLatest();
    getBalance();
  }, [location]);

  function onSwitchTheme() {
    localStorage.setItem("isDarkMode", (!isDarkMode).toString());
    setIsDarkMode((previousValue) => !previousValue);
    document.body.className =
      localStorage.getItem("isDarkMode") === "true"
        ? "dark-theme"
        : "light-theme";
  }

  async function getBalance() {
    const response = await Api.Get(`/token/balance/KBS`);
    if (response.data.status === true) {
      setBalance(response.data.balance);
    }
  }

  async function handleSend(values, mode) {
    if (mode === "airdrop") setOpen(false);

    if (mode === "send") {
      setOpen(false);
      setLoading(true);
      const response = await Api.Post(`/token/send_kbs`, values);
      if (response.data.status === true) {
        setLoading(false);
        success({
          centered: true,
          title: "Successfully",
          icon: (
            <span className="anticon">
              <i className="fas fa-check-circle"></i>
            </span>
          ),
          content: <Text>คุณสามารถตรวจสอบรายการได้ที่ My Profile</Text>,
          onOk() {
            getBalance();
          },
        });
      } else {
        setLoading(false);
        error({
          centered: true,
          title: "Unsuccessfully",
          icon: (
            <span className="anticon">
              <i className="fas fa-times-circle"></i>
            </span>
          ),
          content: <Text>{response.data.msg}</Text>,
          onOk() {
            getBalance();
          },
        });
      }
    }
  }

  if (!data) return null;

  return (
    <ConfigProvider
      locale={enUSIntl}
      theme={{
        token,
        components: isDarkMode ? darkAlgorithm : lightAlgorithm,
      }}
    >
      <Layout>
        <Header className="ant-layout-header-mobile">
          <div style={{ paddingInline: "16px" }}>
            <Row justify={"space-between"}>
              <Col>
                <Space size={"small"}>
                  <DrawerForm
                    resize={{
                      maxWidth: window.innerWidth * 0.8,
                      minWidth: 300,
                    }}
                    trigger={
                      <Button
                        size="small"
                        type="text"
                        icon={<i className="far fa-bars"></i>}
                      ></Button>
                    }
                    drawerProps={{
                      destroyOnClose: true,
                      placement: "left",
                      headerStyle: {
                        display: "none",
                      },
                      footerStyle: {
                        display: "none",
                      },
                    }}
                  >
                    <Menu
                      selectedKeys={[location.pathname]}
                      mode="inline"
                      items={generateMenu(data)}
                    />
                  </DrawerForm>

                  <Space size={2}>
                    <Avatar src="/icon-512x512.png" size={28} />
                    <span>KUBER</span>
                  </Space>
                </Space>
              </Col>
              <Col>
                <Space size={0}>
                  <Dropdown
                    menu={{
                      items: menuDropdown,
                      onClick: handleMenuClick,
                    }}
                    trigger={["click"]}
                  >
                    <Button type="text" size="small">
                      <Space size={4}>
                        <Text strong>{data?.name}</Text>
                        <Avatar src={data?.avatar} shape="square" size={28} />
                      </Space>
                    </Button>
                  </Dropdown>
                  <Button
                    size="small"
                    type="text"
                    icon={<i className="far fa-bullhorn"></i>}
                  ></Button>
                </Space>
              </Col>
            </Row>
          </div>
          <div style={{ paddingInline: "16px" }}>
            <Row justify={"space-between"}>
              <Col>
                <AutoComplete
                  value={keyword}
                  options={options}
                  style={{
                    width: 200,
                  }}
                  onSelect={handleSelect}
                  onSearch={debounce(handleSearch, 300)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <Input
                    placeholder="Find a username"
                    prefix={<i className="fas fa-search"></i>}
                  />
                </AutoComplete>
              </Col>
              <Col>
                <Space size={0} split={<Divider type="vertical" />}>
                  <Paragraph
                    copyable={{
                      text: data ? data.wallet : "",
                    }}
                    style={{ margin: 0 }}
                  >
                    <small>
                      {data ? addressFormat(data.wallet, 4, 4) : ""}
                    </small>
                  </Paragraph>
                  <Space size={4}>
                    <Image src="/token/KBS.png" alt="KBS" width={14} />
                    <Text strong>
                      <small>{balance}</small>
                    </Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </div>
        </Header>

        <Header>
          <Row>
            <Col span={10}>
              <Space size={"large"}>
                <Badge
                  status="processing"
                  text={
                    <Space size={4}>
                      <Text strong>
                        <CountUp end={data?.members} separator="," />
                      </Text>
                      <Text type="secondary">members</Text>
                    </Space>
                  }
                />

                <Space size={4}>
                  <Text type="secondary">
                    <i className="far fa-send-backward"></i>
                  </Text>
                  <Text strong>
                    <CountUp end={data?.nfts} separator="," />
                  </Text>
                  <Text type="secondary">NFTs</Text>
                </Space>
                <Space size={4}>
                  <Text type="secondary">
                    <i className="far fa-bullseye-arrow"></i>
                  </Text>
                  <Text strong>
                    <CountUp end={data?.missions} separator="," />
                  </Text>
                  <Text type="secondary">missions</Text>
                </Space>
              </Space>
            </Col>

            <Col span={4} align="center">
              <div className="header-logo">
                <div className="header-curve-left"></div>
                <div className="header-triangle-left"></div>
                <Space direction="vertical" size={0}>
                  <Avatar src="/icon-512x512.png" />
                  <span>KUBER</span>
                </Space>
                <div className="header-curve-right"></div>
                <div className="header-triangle-right"></div>
              </div>
            </Col>

            <Col span={10} align="right">
              <Space>
                <AutoComplete
                  value={keyword}
                  options={options}
                  style={{
                    width: 200,
                  }}
                  onSelect={handleSelect}
                  onSearch={debounce(handleSearch, 300)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <Input
                    placeholder="Find a username"
                    prefix={<i className="fas fa-search"></i>}
                  />
                </AutoComplete>

                <Button
                  type="text"
                  size="small"
                  icon={<i className="far fa-sign-out"></i>}
                  danger
                  onClick={handleSignOut}
                >
                  Sign out
                </Button>
              </Space>
            </Col>
          </Row>
        </Header>
        <Layout hasSider>
          <Sider collapsed={true}>
            <Menu
              selectedKeys={[location.pathname]}
              mode="inline"
              items={generateMenu(data)}
            />
          </Sider>

          <Content>
            <div className="content-main">
              <div className="content-main-header">
                <Row justify={"space-between"} align={"middle"}>
                  <Col>
                    <Space size={"large"}>
                      <Space size={4}>
                        <Text type="secondary">
                          <small>Rank :</small>
                        </Text>
                        <Tooltip title={data?.rank.rank_name.toUpperCase()}>
                          <Image
                            src={`/rank/${data?.rank.rank_name}.png`}
                            alt={data?.rank.rank_name}
                            preview={false}
                            width={40}
                          />
                        </Tooltip>
                      </Space>
                      <Space size={4}>
                        <Text type="secondary">
                          <small>Core Value Pt :</small>
                        </Text>
                        <Title level={5} style={{ margin: 0 }}>
                          {data?.rank.sum_score}
                        </Title>
                      </Space>
                      <Space size={4}>
                        <Text type="secondary">
                          <small>NFT Mission Pt :</small>
                        </Text>
                        <Title level={5} style={{ margin: 0 }}>
                          {data?.nft_mission_pt}
                        </Title>
                      </Space>
                    </Space>
                  </Col>
                  <Col>
                    <Space>
                      <Space
                        direction="vertical"
                        size={0}
                        style={{ textAlign: "right" }}
                      >
                        <Title level={5} style={{ margin: 0 }}>
                          {data?.name}
                        </Title>
                        <Space size={0} split={<Divider type="vertical" />}>
                          <Paragraph
                            copyable={{
                              text: data ? data.wallet : "",
                            }}
                            style={{ margin: 0 }}
                          >
                            <small>
                              {data ? addressFormat(data.wallet, 4, 4) : ""}
                            </small>
                          </Paragraph>
                          <Space size={4}>
                            <Image src="/token/KBS.png" alt="KBS" width={14} />
                            <Text strong>
                              <small>{balance}</small>
                            </Text>
                          </Space>
                        </Space>
                      </Space>
                      <Avatar
                        src={data?.avatar}
                        shape="square"
                        size={"large"}
                      />
                    </Space>
                  </Col>
                </Row>
              </div>
              <div className="content-main-body">
                <Outlet />
              </div>
            </div>
            <div className="right-sider">
              <div className="right-sider-watchlist">
                <Space
                  direction="vertical"
                  style={{ display: "flex", width: "100%" }}
                >
                  <Text strong>
                    <small>{"watchlists".toUpperCase()}</small>
                  </Text>
                  <div className="right-sider-watchlist-item">
                    <Row gutter={[5, 0]} align="middle">
                      <Col flex="34px">
                        <Image src="/token/KBS.png" width={34} />
                      </Col>
                      <Col flex="auto">
                        <Row>
                          <Col span={24}>
                            <Row justify="space-between">
                              <Col>
                                <Space direction="vertical" size={0}>
                                  <Text strong>
                                    <small>KBS</small>
                                  </Text>
                                  <Text>
                                    <small>
                                      {numberFormatter.format(supplyKBS)}
                                    </small>
                                  </Text>
                                </Space>
                              </Col>
                              <Col align="right">
                                <Space direction="vertical" size={0}>
                                  {analyKBS ? (
                                    analyKBS.percent > 0 ? (
                                      <Text strong type="success">
                                        <small>{`+${analyKBS.percent}%`}</small>
                                      </Text>
                                    ) : (
                                      <Text strong type="danger">
                                        <small>{`${analyKBS.percent}%`}</small>
                                      </Text>
                                    )
                                  ) : null}
                                  <Text type="success">
                                    <small>
                                      {analyKBS
                                        ? `+${analyKBS.increase}`
                                        : "+0"}
                                    </small>
                                  </Text>
                                </Space>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="right-sider-watchlist-item">
                    <Row gutter={[5, 0]} align="middle">
                      <Col flex="34px">
                        <Image src="/token/KBG.png" width={34} />
                      </Col>
                      <Col flex="auto">
                        <Row>
                          <Col span={24}>
                            <Row justify="space-between">
                              <Col>
                                <Space direction="vertical" size={0}>
                                  <Text strong>
                                    <small>KBG</small>
                                  </Text>
                                  <Text>
                                    <small>
                                      {numberFormatter.format(supplyKBG)}
                                    </small>
                                  </Text>
                                </Space>
                              </Col>
                              <Col align="right">
                                <Space direction="vertical" size={0}>
                                  {analyKBG ? (
                                    analyKBG.percent > 0 ? (
                                      <Text strong type="success">
                                        <small>{`+${analyKBG.percent}%`}</small>
                                      </Text>
                                    ) : (
                                      <Text strong type="danger">
                                        <small>{`${analyKBG.percent}%`}</small>
                                      </Text>
                                    )
                                  ) : null}
                                  <Text type="success">
                                    <small>
                                      {analyKBG
                                        ? `+${analyKBG.increase}`
                                        : "+0"}
                                    </small>
                                  </Text>
                                </Space>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Space>
              </div>
              <div className="right-sider-latest">
                <ProList
                  rowKey="name"
                  headerTitle={
                    <Text strong>
                      <small>{"latest".toUpperCase()}</small>
                    </Text>
                  }
                  toolBarRender={() => {
                    return [
                      <Text type="secondary">
                        <small>Send KBS</small>
                      </Text>,
                    ];
                  }}
                  dataSource={latest}
                  metas={{
                    title: {
                      dataIndex: "created_at",
                      render: (item) => (
                        <Text type="secondary">
                          <small>
                            {moment(item).format("DD/MM/YYYY, HH:mm:ss")}
                          </small>
                        </Text>
                      ),
                    },

                    description: {
                      dataIndex: "desc",
                      render: (_, record) => (
                        <Card
                          className="right-sider-card"
                          bodyStyle={{ padding: "10px 12px" }}
                        >
                          <Space direction="vertical">
                            <Space>
                              <Link
                                to={`/profile/${record.sender._id}`}
                                target="_blank"
                              >
                                <Tooltip
                                  title={generateUsername(record.sender.name)}
                                >
                                  <Avatar
                                    src={record.sender.avatar}
                                    size={"small"}
                                  />
                                </Tooltip>
                              </Link>
                              <Title level={5} style={{ margin: 0 }}>
                                <small>{`send ${record.amount} KBS to`}</small>
                              </Title>
                              <Link
                                to={`/profile/${record.receiver._id}`}
                                target="_blank"
                              >
                                <Tooltip
                                  title={generateUsername(record.receiver.name)}
                                >
                                  <Avatar
                                    src={record.receiver.avatar}
                                    size={"small"}
                                  />
                                </Tooltip>
                              </Link>
                            </Space>
                            <Paragraph style={{ margin: 0 }}>
                              <small>{record.comment}</small>
                            </Paragraph>
                            <Space size={4}>
                              <Tooltip
                                title={getCoreValue(record.core_value - 1)}
                              >
                                <Avatar
                                  src={`/core_value/${record.core_value}.svg`}
                                  size={"small"}
                                />
                              </Tooltip>
                              <Text type="secondary">
                                <small>{`${record.points} points`}</small>
                              </Text>
                            </Space>
                          </Space>
                        </Card>
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </Content>
        </Layout>
        <Footer>
          <div className="footer-mode">
            {/*<Switch
              checked={isDarkMode}
              checkedChildren={<i className="fas fa-moon"></i>}
              unCheckedChildren={<i className="fas fa-moon"></i>}
              onChange={onSwitchTheme}
            />*/}
          </div>
          <div className="footer-body">
            <Text>© Bitkub</Text>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                getBalance();
                setOpen(true);
              }}
            >
              My Wallet
            </Button>
          </div>

          <div className="footer-body-mobile">
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                getBalance();
                setOpen(true);
              }}
              className="button-mobile"
              icon={<i className="far fa-wallet"></i>}
            ></Button>
          </div>
        </Footer>

        {open === true && (
          <Wallet
            open={open}
            balance={balance}
            onClose={() => setOpen(false)}
            onFinish={handleSend}
          />
        )}
        {loading === true && <Waiting open={loading} />}
      </Layout>
    </ConfigProvider>
  );
};

export default LayoutMain;
